<template>
  <transition>
  <div  class="miantitle" v-show="show">
    <Space direction="vertical" align="center" :size="50">
    <Row>
      <span class="iconfont" style="font-size:90px">&#xeb7e;</span>
      <h1 style="font-size:90px">企业微信关联</h1>
    </Row>
    <Row>
      <Space :size="50">
        <Col>
          <Space direction="vertical" align="center" :size="50">
          <Row>      
              <Col>
                <Space direction="vertical" align="center">
                  <span class="iconfont" style="font-size:90px">&#xe613;</span>     <!-- 发送员工消息 -->
                  <h1 style="width:300px;text-align:center">批量发送员工消息</h1>
                </Space>
              </Col>
              <Col>
                <Space direction="vertical" align="center">
                  <span class="iconfont" style="font-size:90px">&#xe74d;</span>     <!-- 自定义消息内容 -->
                  <h1 style="width:300px;text-align:center">自定义消息内容</h1>
                </Space>
              </Col>
              <!-- <template #split> <h1>|</h1> </template> -->
          </Row>
          <Row> 
              <Col>
                <Space direction="vertical" align="center">
                  <span class="iconfont" style="font-size:90px">&#xe62b;</span>   <!-- 消息类型多样 -->
                  <h1 style="width:300px;text-align:center">消息类型多种多样</h1>
                </Space>
              </Col>
              <Col>
                <Space direction="vertical" align="center" >
                  <span class="iconfont" style="font-size:90px">&#xe8b9;</span>
                  <!--<Icon type="ios-color-filter" :size="90"/>      消息类型多样 -->
                  <h1 style="width:300px;text-align:center">自定义表格数据</h1>
                </Space>
              </Col>
          </Row>
          </Space>
        </Col>
        <Col>
          <Row>
            <div class="container"></div>
          </Row>
          <Row>
            <Button class="right-bottom" to="/product/wxworkMsg">点击试用</Button>
            <Button class="right-bottom" to="/product/wxworkMsg">查看教程</Button>
          </Row>
        </Col>
      </Space>
    </Row>
  </Space>
    </div>
  </transition>

</template>

 
<script>
export default {
  name: 'WXWorkView',
  data() {
    return{
      show: false
    }
  },
  mounted(){
    this.show = true;
  },
  methods:{
  }
}
</script>


<style lang="less" scoped>
.miantitle{
  color: #0b657b;
  position: absolute;
  top: 45%;
  left: 18%;
  transform: translateY(-50%);
  /* 或者左右居中 */
}

  .v-enter-from {
    opacity: 0;
  }
  /* 开始，结束规定动画的效果，这个必须要有，否则不生效 */
  .v-enter-active {
    transition: opacity 3s ease;
  }
  /* 出去，结束 */
  .v-enter-to {
    opacity: 1;
  }

  .container {
    background:#0b657b3b;
    width:560px;
    height:350px;
    border-radius: 10px;
  }
  .right-bottom {
    position: absolute; /* 设置绝对定位 */
    bottom: 0; /* 距离容器底部0像素 */
    right: 0; /* 距离容器右侧0像素 */
    width: 130px; /* 设置元素的宽度 */

  }

</style>