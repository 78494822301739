import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './my-theme/index.less'
import ViewUIPlus from 'view-ui-plus'
import router from './router'
import './assets/css/iconfont.css'


const app = createApp(App)

app.use(VueAxios, axios)
.use(router)
.use(ViewUIPlus).mount('#app')
