<template>
  <div style="margin:20px;color: #0b657b;">
    <Form :model="wxworkinfo" ref="wxworkinfo" label-width="80" inline>
        <!-- <FormItem label="企业id" prop="id">
          <Input v-model="wxworkinfo.id"></Input>
        </FormItem>
        <FormItem label="应用id" prop="agentId">
          <Input v-model="wxworkinfo.agentId"></Input>
        </FormItem>
        <FormItem label="应用秘钥" prop="secret">
          <Input v-model="wxworkinfo.secret"></Input>
        </FormItem> -->
      <!-- </Space> -->
      <!-- <FormItem label-width="0">
        <Button @click="fieldModal = true">配置字段</Button>
      </FormItem> -->
        <FormItem label-width="0">
          <Upload>
            <Button>上传数据</Button>
          </Upload>
        </FormItem>
        <FormItem label-width="0">
          <Upload>
            <Button>立即发送</Button>
          </Upload>
        </FormItem>
        <FormItem label-width="0">
          <Upload>
            <Button>上传认证文件</Button>
          </Upload>
        </FormItem>
    </Form>
   <Table border :columns="columns" :data="data" :height="tabelHeight" ></Table>
  </div>
</template>
  
<script>
import { Form,Upload,Table } from 'view-ui-plus';
import { resolveComponent } from 'vue';
export default {
    name: 'WXWorkMsg',
    data() {
        return {
            wxworkinfo:{
              id:'',
              agentId:'',
              secret:''
            },
            columns:[
              {
                title: '企业微信用户id',
                key: 'userno',
                align: 'center',
                maxWidth:150
              },{
                title: '发送内容',
                key: 'content',
                align: 'center'
              },{
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    maxWidth:150,
                    render: (h, params) => {
                        return h('div', [h(resolveComponent('Button'), {
                                type: 'primary',
                                size: 'small',
                                disabled:  Boolean(params.row.isSend),
                                style: {
                                    marginRight: '5px'
                                },
                                onClick: () => {
                                    this.delete(params.index);
                                }
                            }, {
                                default() {
                                    return '删除';
                                }
                            })
                        ]);
                    }
                }
            ],
            data:[],
            fieldModal:false,
            fieldColumn:[

            ]
        };
    },
    computed:{
        tabelHeight(){
         return window.innerHeight-210;
        }
    },
    mounted: function () {
        
    },
    methods: {
       
    },
    components:{Form,Upload,Table}
}
</script>

<style lang="less" scoped>
.classindex{
    font-weight: bolder;
    font-size: 100px;
}

.imgclass{
  margin-top: 10%;
  display:flex;
  justify-content: space-around;
}
.contentclass{
  text-align:center;
}
</style>