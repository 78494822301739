import { createRouter, createWebHistory } from 'vue-router' 
import Index from '@/views/Index.vue'
import IndexContent from '@/views/IndexContent.vue'
import Support from '@/views/Support.vue'
import WXWork from '@/views/WXWork.vue'
import JDY from '@/views/JDY.vue'
import Product from '@/views/Product.vue'
import WXWorkMsg from '@/views/WXWorkMsg.vue'
import JdyData from '@/views/JdyData.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    children:[
      {
        path: 'index',
        component: IndexContent,
      },{
        path: 'support',
        component: Support,
      },{
        path: 'wxwork',
        component: WXWork,
      },{
        path: 'jdy',
        component: JDY,
      }
    ]
  },{
    path: '/product',
    name: 'product',
    component: Product,
    children:[
      {
        path: 'wxworkMsg',
        component: WXWorkMsg
      },{
        path: 'jdyData',
        component: JdyData
      }
    ]
  }

]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  }
})


//登录拦截
 router.beforeEach((to, from, next) => {

  if (to.path == "/") {
    next({ path: '/index' });
  }else {
    next(); // 否则，正常进入该路由
  }
//   // let isLogin = sessionStorage.getItem("isLogin");// 这里判断用户是否登录，例如通过token或其他方式
//   // if (!isLogin && to.path != "/") {
//     next({ name: 'index' }); // 如果不是访问登录页且未登录，重定向到登录页
//   // } else {
//   //   next(); // 否则，正常进入该路由
//   // }
// });
// // router.beforeEach((to,from,next)=>{
//   next();
//   // if (to.path != "/" && from != undefined) {//requireAuth若为true则该路由需要判断是否登录
//   //   if (localStorage.userName) {//判断当前的userName数据是否存在
//   //     next();
//   //   }
//   //   else {
//   //     next({//返回登录页面
//   //       path: '/',
//   //       query: {redirect: to.fullPath}
//   //     })
//   //   }
//   // }
//   // else {
//   //   next();
//   // }
})



export default router
