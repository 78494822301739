<template>
  <div  class="miantitle" v-show="show">
    <Space direction="vertical" align="center" :size="50">
    <Row>
      <span class="iconfont" style="font-size:90px">&#xe634;</span>
      <h1 style="font-size:90px">简道云关联</h1>
    </Row>
    <Row>
      <Space :size="50">
        <Col>
          <Space direction="vertical" align="center" :size="50">
            <Row>      
              <Col>
                <Space direction="vertical" align="center">
                  <span class="iconfont" style="font-size:70px">&#xe616;</span>     <!-- 上传下载文件图片 -->
                  <h1 style="width:300px;text-align:center;">上传下载文件图片</h1>
                </Space>
              </Col>
              <Col>
                <Space direction="vertical" align="center" size="small">
                  <span class="iconfont" style="font-size:70px;">&#xe700;</span>   <!-- 多表单同时下载 -->
                  <h1 style="width:300px;text-align:center;">多表单同时下载</h1>
                </Space>
              </Col>
            </Row>
            <Row> 
              <Col>
                <Space direction="vertical" align="center">
                  <span class="iconfont" style="font-size:70px;">&#xe940;</span>    <!-- 直连本地数据库 -->
                  <h1 style="width:300px;text-align:center;">直连本地数据库</h1>
                </Space>
              </Col>
              <Col>
                <Space direction="vertical" align="center" size="small">
                  <Icon type="ios-time" :size="90" style="height:105px"/>     <!-- 可定时触发 -->
                  <h1 style="width:300px;text-align:center;">可定时触发</h1>
                </Space>
              </Col>
            </Row>
          </Space>
        </Col>
        <Col>
          <div class="container">
            <Button class="right-bottom">点击试用</Button>
          </div>
        </Col>
      </Space>
    </Row>
    </Space>
    </div>

</template>

 
<script>
export default {
  name: 'WXWorkView',
  data() {
    return{
      show: false
    }
  },
  mounted(){
    this.show = true;
  },
  methods:{
  }
}
</script>


<style lang="less" scoped>
.miantitle{
  color: #0b657b;
  position: absolute;
  top: 45%;
  left: 18%;
  transform: translateY(-50%);
  /* 或者左右居中 */
}
  .wrap{
    top: 15%;
    left: 50%;
    mix-blend-mode: multiply;
    display: inline-block;
    position: relative;
    width:300px;
  }
  img.ivu-image-img{
    border-bottom-left-radius:25px;  //无效
  }
  .v-enter-from {
    opacity: 0;
  }
  /* 开始，结束规定动画的效果，这个必须要有，否则不生效 */
  .v-enter-active {
    transition: opacity 3s ease;
  }
  /* 出去，结束 */
  .v-enter-to {
    opacity: 1;
  }
  .container {
    background:#0b657b3b;
    width:560px;
    height:350px;
    border-radius: 10px;
  }
  .right-bottom {
    position: absolute; /* 设置绝对定位 */
    bottom: 0; /* 距离容器底部0像素 */
    right: 0; /* 距离容器右侧0像素 */
    width: 100px; /* 设置元素的宽度 */
    height: 50px; /* 设置元素的高度 */
  }
</style>