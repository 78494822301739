<template>
  <div  class="miantitle">
    <transition>
      <h1 v-show="showTitle" style="font-size:30px">如果您有定制需求</h1>
    </transition>
    <transition>
      <h1 v-show="showTitle" style="font-size:30px">请扫右侧的二维码联系我</h1>
    </transition>
    <!-- <transition>
      <h1 v-show="showTitle" style="font-size:30px">或留下您的联系方式</h1>
    </transition> -->
    <!-- <Row>
      <Col>
        <transition>
          <Input v-show="showTitle" v-model="name" placeholder="姓名"></Input>
        </transition>
      </Col>
      <Col>
        <transition>
          <Input v-show="showTitle" v-model="mobile" placeholder="手机号"></Input>
        </transition>
      </Col>
    </Row> -->
    <transition>
      <h1 v-show="showTitle" style="font-size:30px">我将为您竭诚服务</h1>
    </transition>
    <!-- <transition>
      <h1 v-show="showTitle" style="font-size:30px">如果您有学习需求</h1>
    </transition>
    <transition>
      <h1 v-show="showTitle" style="font-size:30px">请关注我的B站账号</h1>
    </transition>
    <transition>
      <h1 v-show="showTitle" style="font-size:30px">里面会有我的学习过程视频</h1>
    </transition> -->
  </div>
  <div class="wrap">
    <transition>
      <Image v-show="showImg" :src="require('@/assets/wxpic.jpg')"></Image>
    </transition>
  </div>
  
</template>

 
<script>
export default {
  name: 'SupportView',
  data() {
    return{
      showTitle:false,
      showImg:false,
      name:'',
      mobile:''
    }
  },
  mounted(){
    this.showImg = true;
    this.showTitle = true;
  },
  methods:{
  }
}
</script>


<style lang="less" scoped>
.miantitle{
  color: #0b657b;
  position: absolute;
  top: 36%;
  left: 30%;
  transform: translateY(-50%);
  /* 或者左右居中 */
}
  .wrap{
    top: 20%;
    left: 50%;
    mix-blend-mode: multiply;
    display: inline-block;
    position: relative;
    width:300px;
  }
  img.ivu-image-img{
    border-bottom-left-radius:25px;  //无效
  }
  .v-enter-from {
    opacity: 0;
  }
  /* 开始，结束规定动画的效果，这个必须要有，否则不生效 */
  .v-enter-active {
    transition: opacity 3s ease;
  }
  /* 出去，结束 */
  .v-enter-to {
    opacity: 1;
  }

</style>