<template>
  <div class="layout">
  <Layout>
    <Header style="background: #0b657b;">
      <Menu mode="horizontal" theme="dark" active-name="1">
        <div style="width:255px;float: left;position: relative;">
          <Image :src="require('@/assets/shlogo.png')" fit="contain" style="width:30px;height:30px"></Image>
          <h1 style="color: #fff;width:200px;float: right;font-size:24px">舒航科技1111</h1>
        </div>
        <div class="layout-nav">
          <MenuItem name="index" to="/index"><Icon type="md-flame" />首页</MenuItem>
          <Submenu  name="product" >
            <template #title>
              <Icon type="md-apps" />产品
            </template>
            <MenuItem name="wxwork" to="/wxwork">企业微信关联</MenuItem>
            <MenuItem name="jdy" to="/jdy">简道云关联</MenuItem>
          </Submenu>
          <MenuItem name="support" to="/support"><Icon type="ios-hammer" />服务支持</MenuItem>
          <MenuItem name="about"><Icon type="md-information-circle" />关于</MenuItem>
        </div>
    </Menu>
    </Header>
    <Layout>
      <Sider hide-trigger :style="{background: '#fff'}" >
            <Menu theme="light" width="auto" :class="menuitemClasses">
              <Submenu name="wxwork">
                <template #title>
                  <span class="iconfont">&#xeb7e;</span>
                    企业微信关联
                </template>
                <MenuItem name="wxworkmsg" to="/product/wxworkMsg">
                  <span class="iconfont">&#xe644;</span>
                    <span>发送消息</span>
                </MenuItem>
                <!-- <MenuItem name="wxworkperson">
                  <span class="iconfont">&#xe613;</span>
                    <span>员工关联</span>
                </MenuItem> -->
              </Submenu>
              <Submenu name="jdy">
                <template #title>
                  <span class="iconfont">&#xe634;</span>
                    简道云关联
                </template>
                <MenuItem name="data" to="/product/jdyData">
                  <span class="iconfont">&#xe8b9;</span>
                    <span>数据管理</span>
                </MenuItem>
                <!-- <MenuItem name="field">
                  <span class="iconfont">&#xe6ac;</span>
                    <span>字段管理</span>
                </MenuItem> -->
              </Submenu>
            </Menu>
      </Sider>
      <Content style="background: radial-gradient(ellipse at center, #fffeea 0%, #fffeea 35%, #B7E8EB 100%);height:88vh;position: relative;">
          <RouterView></RouterView>
      </Content>
    </Layout>
    <Footer class="layout-footer-center">站长：星辰闪闪发亮 | 晋ICP备2024046353号</Footer>
  </Layout>
  </div>
    <!-- <Modal v-model="showWxPic" :footer-hide="true" :transfer="true" :closable="false" @on-cancel="changeview" :scrollable="false" :lock-scroll="false" 
        style="text-align: center;width: fit-content;">
        <Image :src="require('@/assets/wxpic.jpg')" width="300px"></Image>
    </Modal> -->
</template>
  
<script>
export default {
  name: 'IndexView',
  data() {
    return{
        showWxPic:false,
        theme: 'light',
        showTitle1:false,
        showTitle2:false,
        showTitle3:false,
        showTitle4:false,
        showTitle5:false,
        showTitle6:false,
        showTitle7:false,
        showTitle8:false,
        showTitle9:false,
        showTitle10:false,
        showTitle11:false,
        showTitle12:false
    }
  },
  mounted(){
    this.showTitle1 = true;
    let that= this;
    for(let i=1;i<13;i++){
      if(i == 1){
        setTimeout(function() {
          that.showTitle2 = true;
        }, 900);
      }else{
        setTimeout(function() {
          that['showTitle'+i] = true;
        }, 200*i);
      }

    }
  },
  methods:{
    changeview(){
        console.log(document.body.style.overflow);
        document.body.style.overflow = 'hidden'; // 隐藏整个页面的滚动条
    },
    toOtherPage(){
        // 在Vue组件中
        this.$router.push('/wxworkMsg');
    }
  }
}
</script>

<style lang="less" scoped>
.ivu-menu-dark{
  background: #0b657b;
}
.layout{
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.layout-nav{
  width: 420px;
  margin: 0 auto;
  margin-right: 20px;
}
.layout-footer-center{
  z-index:1;
  text-align: center;
  line-height: 0.5;
  transform: translateY(-20%);
}
.layout .ivu-layout-header {
  background: #0b657b;
}
.layout-topMiddleBottom .ivu-menu-light{
  background:none;
  color: #fff
}
  
  .ocean {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #015871;
  }
  
  .wave {
    background: url(@/assets/wave.svg) repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
            animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
  }
  
  .wave:nth-of-type(2) {
    top: -175px;
    -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
            animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
  }
  
  @-webkit-keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }
  
  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }
  @-webkit-keyframes swell {
    0%, 100% {
      transform: translate3d(0, -25px, 0);
    }
    50% {
      transform: translate3d(0, 5px, 0);
    }
  }
  @keyframes swell {
    0%, 100% {
      transform: translate3d(0, -25px, 0);
    }
    50% {
      transform: translate3d(0, 5px, 0);
    }
  }

  /* 进入，开始 */
  .v-enter-from {
    opacity: 0;
  }
  /* 开始，结束规定动画的效果，这个必须要有，否则不生效 */
  .v-enter-active {
    transition: opacity 3s ease;
  }
  /* 出去，结束 */
  .v-enter-to {
    opacity: 1;
  }

  
</style>