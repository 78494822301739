<template>
  <div class="layout">
  <Layout>
    <Header style="background: #0b657b;">
      <Menu mode="horizontal" theme="dark" active-name="1">
        <div style="width:255px;float: left;position: relative;">
          <Image :src="require('@/assets/shlogo.png')" fit="contain" style="width:30px;height:30px"></Image>
          <h1 style="color: #fff;width:200px;float: right;font-size:24px">舒航科技</h1>
        </div>
        <div class="layout-nav">
          <MenuItem name="index" to="/index"><Icon type="md-flame" />首页</MenuItem>
          <Submenu  name="product" >
            <template #title>
              <Icon type="md-apps" />产品
            </template>
            <MenuItem name="wxwork" to="/wxwork">企业微信关联</MenuItem>
            <MenuItem name="jdy" to="/jdy">简道云关联</MenuItem>
          </Submenu>
          <MenuItem name="support" to="/support"><Icon type="ios-hammer" />服务支持</MenuItem>
          <MenuItem name="about"><Icon type="md-information-circle" />关于</MenuItem>
        </div>
    </Menu>
    </Header>
    <Content style="background: radial-gradient(ellipse at center, #fffeea 0%, #fffeea 35%, #B7E8EB 100%);height:88vh;position: relative;">
        <RouterView></RouterView>
    </Content>
    <Footer class="layout-footer-center">
      <Poptip  placement="top" trigger="hover">
        站长：星辰闪闪发亮 
        <template #content>
            <Space direction="vertical" align="center">
              <div>公众号</div>
              <Image :src="require('@/assets/wxpic.jpg')" :width="100"></Image>
            </Space>
            <!-- <Space direction="vertical" align="center">
              <div>哔哩哔哩</div>
              <Image :src="require('@/assets/wxpic.jpg')" :width="100"></Image>
            </Space>
            <Space direction="vertical" align="center">
              <div>微信</div>
              <Image :src="require('@/assets/wxpic.jpg')" :width="100"></Image>
            </Space> -->
        </template>
      </Poptip>
       | 晋ICP备2024046353号
    </Footer>
  </Layout>
  </div>
    <!-- <Modal v-model="showWxPic" :footer-hide="true" :transfer="true" :closable="false" @on-cancel="changeview" :scrollable="false" :lock-scroll="false" 
        style="text-align: center;width: fit-content;">
        <Image :src="require('@/assets/wxpic.jpg')" width="300px"></Image>
    </Modal> -->
</template>
  
<script>
export default {
  name: 'IndexView',
  data() {
    return{
        showWxPic:false,
        theme: 'light',
        showTitle1:false,
        showTitle2:false,
        showTitle3:false,
        showTitle4:false,
        showTitle5:false,
        showTitle6:false,
        showTitle7:false,
        showTitle8:false,
        showTitle9:false,
        showTitle10:false,
        showTitle11:false,
        showTitle12:false
    }
  },
  mounted(){
    this.showTitle1 = true;
    let that= this;
    for(let i=1;i<13;i++){
      if(i == 1){
        setTimeout(function() {
          that.showTitle2 = true;
        }, 900);
      }else{
        setTimeout(function() {
          that['showTitle'+i] = true;
        }, 200*i);
      }

    }
  },
  methods:{
    changeview(){
        console.log(document.body.style.overflow);
        document.body.style.overflow = 'hidden'; // 隐藏整个页面的滚动条
    },
    toOtherPage(){
        // 在Vue组件中
        this.$router.push('/wxworkMsg');
    }
  }
}
</script>

<style lang="less" scoped>
.ivu-menu-dark{
  background: #0b657b;
}
.layout{
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.layout-nav{
  width: 420px;
  margin: 0 auto;
  margin-right: 20px;
}
.layout-footer-center{
  z-index:1;
  text-align: center;
  line-height: 0.5;
  transform: translateY(-10%);
}
.layout .ivu-layout-header {
  background: #0b657b;
}
.layout-topMiddleBottom .ivu-menu-light{
  background:none;
  color: #fff
}
  

  /* 进入，开始 */
  .v-enter-from {
    opacity: 0;
  }
  /* 开始，结束规定动画的效果，这个必须要有，否则不生效 */
  .v-enter-active {
    transition: opacity 3s ease;
  }
  /* 出去，结束 */
  .v-enter-to {
    opacity: 1;
  }

  
</style>