<template>
        <div  class="miantitle">
        <transition>
            <h1 v-show="showTitle1" style="font-size:80px">舒航科技</h1>
        </transition>
        </div>
        <div class="othertitle">
        <transition>
            <span v-show="showTitle2">为</span> 
        </transition>
        <transition>
            <span v-show="showTitle3">您</span> 
        </transition>
        <transition>
            <span v-show="showTitle4" >打</span> 
        </transition>
        <transition>
            <span v-show="showTitle5">通</span> 
        </transition>
        <transition>
            <span v-show="showTitle6">系</span> 
        </transition>
        <transition>
            <span v-show="showTitle7">统</span> 
        </transition>
        <transition>
            <span v-show="showTitle8">最</span> 
        </transition>
        <transition>
            <span v-show="showTitle9">后</span> 
        </transition>
        <transition>
            <span v-show="showTitle10">一</span> 
        </transition>
        <transition>
            <span v-show="showTitle11">公</span> 
        </transition>
        <transition>
            <span v-show="showTitle12">里</span> 
        </transition>
        <!-- <h1>  为您打通系统的最后一公里</h1>  -->
        </div>
        <transition>
          <div class="wrap" v-show="wrapshow">
            <div class="color-overlay"></div>
            <Image :src="require('@/assets/earth.gif')" fit="cover" class="imgclass"></Image>
          </div>
        </transition>
        <div class="ocean">
          <div class="wave"></div>
          <div class="wave"></div>
        </div>
        <!--<h1 class="titleclass" style="font-size:20px;top: 90%;">主营业务：企业微信自动消息推送，定制开发，有需请添加微信
            <Poptip trigger="hover" placement="bottom" fit="cover"  transfer>
                rollow1962
                <template #content>
                    <Image :src="require('@/assets/wxpic.jpg')" width="300px"></Image>
                </template>
            </Poptip>
            详细沟通<Button style="margin-left:10px;margin-bottom:2px" @click="showWxPic=true">查看二维码</Button>
        </h1>
        <h1 class="titleclass" style="font-size:20px;top: 95%;">点击<Button style="margin-left:5px;margin-right:5px;margin-bottom:2px;width:auto" @click="toOtherPage">立即试用</Button>微信推送消息功能</h1>
        -->

</template>

 
<script>
export default {
  name: 'IndexContentView',
  data() {
    return{
        wrapshow:false,
        showTitle1:false,
        showTitle2:false,
        showTitle3:false,
        showTitle4:false,
        showTitle5:false,
        showTitle6:false,
        showTitle7:false,
        showTitle8:false,
        showTitle9:false,
        showTitle10:false,
        showTitle11:false,
        showTitle12:false
    }
  },
  mounted(){
    this.wrapshow = true;
    this.showTitle1 = true;
    let that= this;
    for(let i=1;i<13;i++){
      if(i == 1){
        setTimeout(function() {
          that.showTitle2 = true;
        }, 900);
      }else{
        setTimeout(function() {
          that['showTitle'+i] = true;
        }, 100*i);
      }

    }
  },
  methods:{
  }
}
</script>


<style lang="less" scoped>
  .miantitle{
    color: #0b657b;
    position: absolute;
    top: 36%;
    left: 18%;
    transform: translateY(-50%);
    /* 或者左右居中 */
  }

  .othertitle{
    color: #0b657b;
    position: absolute;
    top: 48%;
    left: 18%;
    transform: translateY(-50%);
    font-size:45px
  }

  /* 进入，开始 */
  .v-enter-from {
    opacity: 0;
  }
  /* 开始，结束规定动画的效果，这个必须要有，否则不生效 */
  .v-enter-active {
    transition: opacity 3s ease;
  }
  /* 出去，结束 */
  .v-enter-to {
    opacity: 1;
  }
  .ivu-icon.ivu-icon-ios-arrow-down.ivu-menu-submenu-title-icon{
    display: none;
  }
  .wrap{
    top: 10%;
    left: 55%;
    mix-blend-mode: multiply;
    display: inline-block;
    position: relative;
  }

  .imgclass{
    width:500px;
    height:500px;
  }
  .color-overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    mix-blend-mode: screen;
    cursor: pointer;
    background-color: #015871;; /* 半透明红色遮罩 */
}
::-webkit-color-swatch {
  border: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::-moz-color-swatch,
::-moz-focus-inner {
  border: none;
}
::-moz-focus-inner {
  padding: 0;
}



.ocean {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #015871;
}

.wave {
  background: url(@/assets/wave.svg) repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
          animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
          animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@-webkit-keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@-webkit-keyframes swell {
  0%, 100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
@keyframes swell {
  0%, 100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
</style>